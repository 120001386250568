<template>
  <div class="categoriesNav" data-testid="categories-nav">
    <div class="categoriesNav__buttons">
      <template v-if="loading">
        <ButtonPlay v-for="i in 4" :key="i" size="sm" :loading="loading">
          Carregando...
        </ButtonPlay>
      </template>
      <template v-else>
        <ButtonPlay v-for="(item, index) in data" :key="index"
          :class="{ active: getOpenedAccordionCategories === item.id && !defaultSelected || defaultSelected?.id === item.id }"
          @click="activateButton(index, item)" size="sm" :loading="loading">
          {{ item.title }}
        </ButtonPlay>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ButtonPlay from "@/components/common/ButtonPlay.vue";

export default {
  props: {
    data: {
      type: Array,
      default: () => [{}],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    defaultSelected: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    ButtonPlay,
  },
  mounted() {
    this.$nextTick(() => {
      this.actionOpenedAccordionCategories(0);
    })
  },
  computed: {
    ...mapGetters({
      getOpenedAccordionCategories: "home/getOpenedAccordionCategories",
    }),
  },
  data() {
    return {
      buttons: [{ text: "Botão 1" }, { text: "Botão 2" }, { text: "Botão 3" }],
    };
  },
  methods: {
    async activateButton(index, item) {
      await this.actionOpenedAccordionCategories(item.id)
      this.$emit('openAccordion', item);
      this.$emit('setSelectedCategory', item);
    },
    ...mapActions({
      actionOpenedAccordionCategories: "home/actionOpenedAccordionCategories",
    }),
  },
};
</script>

<style lang="scss" scoped>
.categoriesNav {
  --margin-default: 3.75rem;

  @include flex-column;

  margin-bottom: var(--margin-default);
  margin-top: 1rem;

  &__title {
    color: var(--fontcolor);
    @extend .body-semibold-14;
  }

  &__buttons {
    @include flex-row;
    flex-wrap: wrap;
    gap: 0.6rem;
  }
}
</style>
